import React from 'react'
import '../../style.css'
import styles from './CommonBanner.module.scss'
import { Box, Divider, Grid, Typography } from '@mui/material'
import img from '../../asserst/img/Otusone banner.png'
import CustomSearch from '../CustomSearch/CustomSearch'
import SimpleButton from '../SimpleButton/SimpleButton'
import { PiArrowFatLineRightFill } from "react-icons/pi";


const CommonBanner = () => {
    const data = [
        {
            "id": 1,
            "label": "CLIENT MEET "
        },
        {
            "id": 2,
            "label": "Project Initiation & Planning"
        },
        {
            "id": 3,
            "label": "Design Phase"
        },
        {
            "id": 4,
            "label": "Development & Testing"
        },
        {
            "id": 5,
            "label": " Deployment & Maintenance"
        }
    ]
    return (
        <Grid className={styles.commonBannerContainer}>
            <Grid className={styles.commonBanner}>
                <Grid className={styles.bgImg}>
                    <img src={img} />
                </Grid>
                <Grid className={styles.bgOpicity}>
                </Grid>
                <Grid className={styles.content}>
                    <Box>
                        <Typography variant='h1' sx={{ paddingInline: 20 }}>Let us handle the IT, so you can focus on soaking up the sunshine</Typography>
                        <Typography>"Crafting Cutting-Edge Solutions for Your Business" encapsulates our commitment to delivering innovative and tailored IT services that propel your business forward. At OTUSONE LLP</Typography>
                        <Box>
                            {/* <CustomSearch /> */}
                        </Box>
                    </Box>
                </Grid>
            </Grid>
            <Grid className={styles.weWork}>
                <Typography variant='h2'>How we works</Typography>
                <Box>
                    {data.map((item: any) => {
                        return (
                            <Box display={"flex"} justifyContent={"space-between"}>
                                <SimpleButton name={item.label} />
                            </Box>
                        )
                    })}
                </Box>
            </Grid>
            <Divider sx={{ marginBlock: 3 }} />
        </Grid>
    )
}

export default CommonBanner